import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  PwDrawerContainerComponent,
  PwDrawerContentComponent,
  PwDrawerFooterComponent,
  PwDrawerHeaderComponent,
  useClosePwDrawer,
} from '@pwiz/infra/drawer';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  SprintGlobalSettingsFormComponent,
  SprintSettingsFormComponent,
} from '@pwiz/sprint/ui';
import { SprintSettingsContainerForm, SprintDetails } from '@pwiz/sprint/ts';
import { CurrentSprintService, SprintService } from '@pwiz/sprint/ui-data';
import { useDetectChanges } from '@pwiz/infra/hooks';
import {
  AddTeam,
  ISprintSettings,
  ISprintTeamSettingsBase,
} from '@pwiz/entity/ts';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { PwToasterService } from '@pwiz/infra/dialog';
import {
  ISprintSettingsDrawerData,
  useSprintSettingsDrawerData,
} from '../hooks/use-open-sprint-settings';
import { $useTeams } from '@pwiz/team/ui-data';

@Component({
  selector: 'pw-sprint-settings-drawer',
  standalone: true,
  imports: [
    CommonModule,
    PwDrawerContainerComponent,
    PwDrawerContentComponent,
    PwDrawerHeaderComponent,
    PwDrawerFooterComponent,
    ReactiveFormsModule,
    SprintGlobalSettingsFormComponent,
    SprintSettingsFormComponent,
    MatTableModule,
    MatButtonModule,
  ],
  templateUrl: './sprint-settings-drawer.component.html',
  styleUrl: './sprint-settings-drawer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SprintSettingsDrawerComponent implements AfterViewInit {
  form = new FormGroup<SprintSettingsContainerForm>({});
  #cd = useDetectChanges();
  #close = useClosePwDrawer();
  #sprintService = inject(SprintService);
  #data = useSprintSettingsDrawerData();
  #toaster = inject(PwToasterService);
  $teamValues = signal<Record<string, AddTeam<ISprintTeamSettingsBase>> | null>(
    null,
  );
  $teams = $useTeams();

  ngAfterViewInit() {
    const { sprint, isSpecificSprint } = this.#data;
    this.#sprintService.getSprintSettings$().subscribe((settings) => {
      this.#setGlobalSettings(settings);
      if (!isSpecificSprint) {
        this.#setTeamSettings(settings, this.#data);
        return;
      }
      if (isSpecificSprint && sprint) {
        const teamSettings = settings.teams[sprint.team.id];
        this.$teamValues.set({
          [sprint.team.id]: {
            mix: sprint.settings?.mix || teamSettings.mix,
            velocity: sprint.settings?.velocity || teamSettings.velocity,
            team: sprint.team,
          },
        });
      }
    });
  }

  #setGlobalSettings({ global: { startDate, duration } }: ISprintSettings) {
    this.form.controls.global?.setValue({ startDate, duration });
    this.form.controls.global?.disable();
  }

  #setTeamSettings(
    { teams }: ISprintSettings,
    { teamIds, sprint }: ISprintSettingsDrawerData,
  ) {
    if (teamIds) {
      const teamSettingsMap = teamIds.reduce<Record<
        string,
        AddTeam<ISprintTeamSettingsBase>
      > | null>((acc, teamId) => {
        acc![teamId] =
          sprint?.team.id === teamId && sprint.settings
            ? { ...sprint.settings, team: teams[teamId].team }
            : teams[teamId];
        return acc;
      }, {});
      this.$teamValues.set(teamSettingsMap);
    } else {
      this.$teamValues.set(teams);
    }
  }

  save() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();
      this.#cd();
      return;
    }
    const { sprint, isSpecificSprint } = this.#data;
    this.#close();
    if (isSpecificSprint && sprint) {
      this.#saveSprintSettings(sprint);
    } else {
      this.#saveSettings();
    }
  }

  #saveSettings() {
    this.#sprintService
      .saveSprintSettings(this.form.value as ISprintSettings)
      .subscribe({
        next: () =>
          this.#toaster.successes('Sprint settings saved successfully', {
            duration: 'medium',
          }),
        error: () =>
          this.#toaster.error('Failed to save sprint settings', {
            duration: 'medium',
          }),
      });
  }

  #saveSprintSettings(sprint: SprintDetails) {
    this.#sprintService
      .saveSpecificSprintSettings(
        sprint,
        this.form.value.teams?.[sprint.team.id] as ISprintTeamSettingsBase,
      )
      .subscribe({
        next: () =>
          this.#toaster.successes(`Sprint ${sprint.index} settings saved`, {
            duration: 'medium',
          }),
        error: () =>
          this.#toaster.error(
            `Failed to save Sprint ${sprint.index} settings`,
            {
              duration: 'medium',
            },
          ),
      });
  }
}
