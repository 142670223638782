import { useOpenPwDrawer, usePwDrawerData } from '@pwiz/infra/drawer';
import { SprintSettingsDrawerComponent } from '../sprint-settings-drawer/sprint-settings-drawer.component';
import { SprintDetails } from '@pwiz/sprint/ts';

export interface ISprintSettingsDrawerData {
  teamIds: string[] | null;
  isSpecificSprint: boolean;
  sprint: SprintDetails | null;
}

export function useOpenSprintSettings() {
  const { open } = useOpenPwDrawer();
  return (
    isSpecificSprint = false,
    teamIds: string[] | null,
    sprint: SprintDetails | null,
  ) =>
    open<ISprintSettingsDrawerData>(SprintSettingsDrawerComponent, {
      name: 'Sprint Settings',
      data: { teamIds: teamIds, isSpecificSprint, sprint },
    });
}

export function useSprintSettingsDrawerData() {
  return usePwDrawerData<ISprintSettingsDrawerData>();
}
