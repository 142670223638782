import { inject, Injector, Provider, StaticProvider } from '@angular/core';
import { PwDrawerService } from '../pw-drawer.service';
import { ComponentType } from '@angular/cdk/overlay';
import { DrawerProps } from '../ts/type';

export function useOpenPwDrawer() {
  const pwDrawer = inject(PwDrawerService);
  const defaultInjector = inject(Injector);
  return {
    open: <TData>(
      component: ComponentType<unknown>,
      options: DrawerProps<TData>,
      providers?: (Provider | StaticProvider)[]
    ) =>
      pwDrawer.open(
        component,
        providers
          ? Injector.create({ providers, parent: defaultInjector })
          : defaultInjector,
        options
      ),
  };
}
